<script>
  // # # # # # # # # # # # # #
  //
  //  AUDIO  BLOCK
  //
  // # # # # # # # # # # # # #

  import get from "lodash/get"

  // PROPS
  export let block = {}

  const audioUrl =
    "https://cdn.sanity.io/files/1tpw92x3/production/" +
    get(block, "audioFile.asset._ref", "")
      .replace("file-", "")
      .replace("-mp3", ".mp3")

  // *** VARIABLES
  let time = 0
  let duration = 0
  let paused = true
  const controlsTimeoutDuration = 2500

  // *** DOM REFERENCES
  let audioEl

  function handleMousemove(e) {
    if (e.which !== 1) return // mouse not down
    if (!duration) return // audio not loaded yet
    const { left, right } = this.getBoundingClientRect()
    time = (duration * (e.clientX - left)) / (right - left)
  }

  function handleMousedown(e) {
    function handleMouseup() {
      if (paused) audioEl.play()
      else audioEl.pause()
      cancel()
    }

    function cancel() {
      e.target.removeEventListener("mouseup", handleMouseup)
    }

    e.target.addEventListener("mouseup", handleMouseup)

    setTimeout(cancel, 200)
  }

  const format = seconds => {
    if (isNaN(seconds)) return "..."
    const minutes = Math.floor(seconds / 60)
    seconds = Math.floor(seconds % 60)
    if (seconds < 10) seconds = "0" + seconds
    return `${minutes}:${seconds}`
  }
</script>

<style lang="scss">.audio-container {
  width: 600px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 900px) {
    .audio-container {
      width: 100%; } }
  .audio-container .audio {
    height: 66px;
    width: 100%;
    display: block;
    color: black;
    line-height: 22px;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 22px;
    margin-top: 22px;
    user-select: none;
    cursor: pointer; }
    .audio-container .audio:active {
      cursor: grab; }
  .audio-container .top-text,
  .audio-container .bottom-text {
    height: 22px;
    font-size: 12px; }
    .audio-container .top-text div,
    .audio-container .bottom-text div {
      width: 50%;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 44px; }
      .audio-container .top-text div.right,
      .audio-container .bottom-text div.right {
        float: right;
        text-align: right; }
  .audio-container progress {
    display: block;
    width: 100%;
    height: 44px;
    z-index: 80;
    -webkit-appearance: none;
    appearance: none; }
    .audio-container progress::-webkit-progress-value {
      background-color: #a4a4a4; }
    .audio-container progress::-webkit-progress-bar {
      background-color: #c4c4c4; }
</style>

<div class="audio-container">
  <div
    on:mousemove={handleMousemove}
    on:mousedown={handleMousedown}
    class="audio">
    <audio
      class="audio-player"
      preload="auto"
      src={audioUrl}
      bind:currentTime={time}
      bind:duration
      bind:paused
      bind:this={audioEl} />

    <div class="top-text">
      {#if block.title}
        <div class="audio-title">{block.title}</div>
      {/if}
      <div class="audio-toggle right">{paused ? 'PLAY' : 'PAUSE'}</div>
    </div>

    <progress value={time / duration || 0} />

    <div class="bottom-text">
      <div class="current-time">{format(time)}</div>
      <div class="total-time right">{format(duration)}</div>
    </div>
  </div>
</div>
