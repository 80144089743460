<script>
  // # # # # # # # # # # # # #
  //
  //  MENUBAR
  //
  // # # # # # # # # # # # # #

  // IMPORTS
  import { navigate } from "svelte-routing";
  import { links } from "svelte-routing";

  // STORES
  import { menuActive } from "../stores.js";

  $: {
    if (searchActive && searchInputElement) {
      searchInputElement.focus();
    }
  }

  // DOM REFERENCES
  let searchInputElement = {};

  // VARIABLES
  let menuOpen = false;
  let searchActive = false;
  let searchInputValue = "";

  $: {
    menuActive.set(menuOpen);
  }
</script>

<style lang="scss">.bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 66px;
  line-height: 22px;
  width: 100vw;
  z-index: 1000;
  background: white;
  overflow: hidden;
  user-select: none;
  border-bottom: 1px solid #e4e4e4;
  font-size: 28px;
  opacity: 1;
  font-size: 17px; }
  .bar.open {
    transition: height 0.2s ease-out;
    height: 330px; }
    @media (max-width: 900px) {
      .bar.open {
        height: 396px; } }

.search {
  opacity: 0; }
  .search input {
    font-size: 17px;
    height: 22px;
    font-family: "Janson Text LT Std", times, serif;
    background: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #e4e4e4;
    outline: 0;
    line-height: 22px;
    font-weight: bold;
    padding-right: 5px;
    margin-right: auto;
    margin-left: auto;
    color: black;
    text-decoration: none;
    display: block;
    text-align: center; }
  .search.active {
    opacity: 1; }

.logo {
  margin-top: 22px;
  margin-left: 42px;
  display: inline-block;
  font-family: "helex", Helvetica, Arial, sans-serif;
  color: inherit;
  text-decoration: none;
  font-weight: 900;
  letter-spacing: 0.05em;
  cursor: pointer; }
  .logo:hover {
    color: #5a5a5a; }
  @media (max-width: 900px) {
    .logo {
      margin-left: auto;
      margin-right: auto;
      display: block;
      text-align: center; }
      .logo:hover {
        color: black; } }
  .logo:active {
    color: #787878; }

.hamburger {
  cursor: pointer;
  float: right;
  margin-right: 42px;
  padding-top: 22px;
  font-family: "helex", Helvetica, Arial, sans-serif;
  color: inherit;
  text-decoration: none;
  font-weight: 900;
  letter-spacing: 0.05em; }
  @media (max-width: 900px) {
    .hamburger {
      display: none; } }
  .hamburger:hover {
    color: #5a5a5a; }
  .hamburger:active {
    color: #787878; }

.menu {
  display: inline-block;
  width: 100%;
  padding-right: 42px;
  padding-left: 42px;
  font-size: 16px;
  z-index: 100;
  background: white;
  overflow: hidden;
  user-select: none;
  padding-top: 22px;
  letter-spacing: 0.05em;
  line-height: 22px; }
  @media (max-width: 900px) {
    .menu {
      padding-right: 14px;
      padding-left: 14px; } }
  .menu .column {
    width: 100%;
    float: right;
    margin-bottom: 22px;
    overflow-y: auto;
    position: relative; }
  .menu .menu-item {
    font-family: "Janson Text LT Std", times, serif;
    font-weight: bold;
    text-align: center;
    color: black;
    text-decoration: none;
    display: block;
    cursor: pointer; }
    @media (max-width: 900px) {
      .menu .menu-item a,
      .menu .menu-item span {
        display: inline-block;
        padding-top: 4px;
        padding-bottom: 4px; } }
    .menu .menu-item a:hover,
    .menu .menu-item span:hover {
      color: #5a5a5a; }
    .menu .menu-item a:active,
    .menu .menu-item span:active {
      color: #787878; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw; }
</style>

<div class="bar" use:links class:open={menuOpen}>
  <!-- Logo -->
  <span
    class="logo"
    on:click={(e) => {
      if (window.matchMedia('(max-width: 900px)').matches) {
        menuOpen = !menuOpen;
        searchActive = false;
        searchInputValue = '';
      } else {
        menuOpen = false;
        searchActive = false;
        searchInputValue = '';
        navigate('/');
      }
    }}>
    PALETTEN
  </span>

  <div
    class="hamburger"
    on:click={(e) => {
      menuOpen = !menuOpen;
      searchActive = false;
      searchInputValue = '';
    }}>
    {#if menuOpen}STÄNG{:else}MENY{/if}
  </div>

  <div
    class="menu"
    use:links
    on:click={(e) => {
      menuOpen = false;
      searchActive = false;
      searchInputValue = '';
    }}>
    <div class="column second">
      <div class="menu-item"><a href="/artiklar">Artiklar</a></div>
      <div class="menu-item"><a href="/tidskrift">Tidskrift</a></div>
      <div class="menu-item"><a href="/projekt">Projekt</a></div>
    </div>
    <div class="column first">
      <div class="menu-item"><a href="/om-paletten">Om Paletten</a></div>
      <div class="menu-item">
        <a href="/prenumerationer">Prenumerationer</a>
      </div>
      <div class="menu-item"><a href="/enkelnummer">Köp enkelnummer</a></div>
      <div class="menu-item"><a href="/nyhetsbrev">Nyhetsbrev</a></div>
      <div class="menu-item"><a href="/kontakt">Kontakt</a></div>
    </div>
    <div class="column third">
      {#if !searchActive}
        <div
          class="menu-item"
          on:click={(e) => {
            searchActive = true;
            e.stopPropagation();
          }}>
          <span>Sök</span>
        </div>
      {/if}
      <div class="search" class:active={searchActive}>
        <input
          bind:this={searchInputElement}
          bind:value={searchInputValue}
          on:keyup={(e) => {
            if (e.keyCode === 13) {
              navigate('/search/' + searchInputValue);
              searchActive = false;
              searchInputValue = '';
              menuOpen = false;
              searchInputElement.blur();
            }
          }} />
      </div>
    </div>
  </div>
</div>

{#if menuOpen}
  <div
    class="overlay"
    on:click={(e) => {
      menuOpen = false;
      searchActive = false;
      searchInputValue = '';
    }} />
{/if}
