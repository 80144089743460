<script>
  // # # # # # # # # # # # # #
  //
  //  ERROR 404
  //
  // # # # # # # # # # # # # #

  // COMPONENTS
  import Footer from "../Components/Footer.svelte";
</script>

<style lang="scss">.placeholder {
  padding-top: calc(66px + 22px);
  text-transform: uppercase;
  line-height: 22px;
  font-family: Helvetica, Arial, sans-serif;
  margin-left: 42px;
  margin-right: 42px;
  min-height: 80vh; }
  @media (max-width: 900px) {
    .placeholder {
      margin-left: 14px;
      margin-right: 14px; } }
</style>

<svelte:head>
  <title>404</title>
</svelte:head>

<div class="placeholder">
  404
  <br />
  Page not found
</div>

<Footer/>
