<script>
  // # # # # # # # # # # # # #
  //
  //  LANDING
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { fade } from "svelte/transition";
  import { urlFor, loadData, renderBlockText } from "../sanity.js";
  import { formattedDate } from "../global.js";
  import _ from "lodash";

  // COMPONENTS
  import Authors from "./Authors.svelte";

  // STORES
  //   import { location, filterTerm } from "../stores.js";
  //   location.set("index");

  // *** PROPS
  export let post = {};

  console.log("first page post");
  console.dir(post);

  let query = "";
  let link = "";

  if (post.postLink) {
    query =
      "*[_id == '" + post.postLink._ref + "']{..., author[]->{title, slug}}[0]";
  } else {
    query = "*[_id == '" + post._id + "']{..., author[]->{title, slug}}[0]";
  }

  if (!post.layout) {
    post.layout = "full";
  }

  if (!post.imageLayout) {
    post.imageLayout = "proportional";
  }

  //   VARIABLES
  let postContent = loadData(query);
  //   // let filteredPosts = [];

  postContent.then((l) => {
    let dir = "";
    if (l._type === "post") {
      dir = "/artiklar/";
    } else if (l._type === "tidskrift") {
      dir = "/tidskrift/";
    } else if (l._type === "projekt") {
      dir = "/projekt/";
    }
    link = dir + l.slug.current;
  });

  // $: {
  //   posts.then(posts => {
  //     filterTerm.set(term);
  //     filteredPosts = [];
  //     window.scrollTo({ top: 0 });
  //     setTimeout(() => {
  //       filteredPosts = term ? posts.filter(p => post.category === term) : posts;
  //     }, 200);
  //   });
  // }
</script>

<style lang="scss">.list-item,
.placeholder {
  display: flex;
  margin-bottom: 22px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 1px solid #e4e4e4;
  flex-wrap: wrap;
  user-select: none; }
  @media (max-width: 900px) {
    .list-item,
    .placeholder {
      flex-wrap: wrap; } }
  .list-item .inner,
  .placeholder .inner {
    width: 100%; }
  .list-item .image,
  .placeholder .image {
    width: 100%;
    display: flex;
    padding-bottom: 22px;
    transition: opacity 0.3s ease-out; }
    .list-item .image img,
    .placeholder .image img {
      max-width: 100%;
      height: 264px;
      object-fit: cover; }
    @media (max-width: 900px) {
      .list-item .image,
      .placeholder .image {
        width: 100%; } }
  .list-item .text,
  .placeholder .text {
    width: 100%;
    padding-right: 20px;
    text-decoration: none;
    color: black; }
    @media (max-width: 900px) {
      .list-item .text,
      .placeholder .text {
        width: 100%; } }
    .list-item .text .title,
    .placeholder .text .title {
      font-size: 17px;
      line-height: 22px;
      font-weight: bold;
      max-width: 45ch; }
      @media (max-width: 900px) {
        .list-item .text .title,
        .placeholder .text .title {
          font-size: 17px; } }
      .list-item .text .title.spaced,
      .placeholder .text .title.spaced {
        margin-bottom: 22px; }
    .list-item .text .author,
    .placeholder .text .author {
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 22px;
      pointer-events: none; }
    .list-item .text .date,
    .placeholder .text .date {
      font-size: 12px;
      font-family: Helvetica, Arial, sans-serif;
      letter-spacing: 0.1em; }
  .list-item:hover .text .title,
  .placeholder:hover .text .title {
    text-decoration: underline; }
  .list-item.padded .inner,
  .placeholder.padded .inner {
    padding-bottom: 22px;
    margin-bottom: 22px; }
  .list-item.padded .text,
  .placeholder.padded .text {
    padding-left: 22px;
    padding-top: 22px; }
  .list-item.padded .ingress,
  .placeholder.padded .ingress {
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 22px; }
  .list-item.padded .image,
  .placeholder.padded .image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .list-item.padded .image img,
    .placeholder.padded .image img {
      max-width: 100%;
      height: 352px;
      object-fit: cover; }

.placeholder {
  background: #f0f0f0; }

.ingress {
  width: 100%; }
</style>

{#await postContent then postContent}
  <a
    href={link}
    class="list-item full"
    in:fade
    class:padded={_.has(post, 'color')}>
    <div
      class="inner"
      style={'background: ' + _.get(post, 'color.hex', 'transparent')}>
      <div class="text">
        <!-- DATE -->
        {#if postContent.tidsPeriod}
          <div class="date">{postContent.tidsPeriod}</div>
        {:else if postContent.publicationDate}
          <div class="date">{formattedDate(postContent.publicationDate)}</div>
        {/if}
        <!-- TITLE -->
        <div class="title" class:spaced={!postContent.author}>{postContent.title}</div>
        <!-- AUTHOR -->
        {#if postContent.author}
          <div class="author">
            <Authors authors={postContent.author} />
          </div>
        {/if}

      </div>
      <!-- IMAGE -->
      {#if post.imageLayout != 'noImage' && postContent.mainImage && postContent.mainImage.asset}
        <div
          class="image"
          style={'background: ' + _.get(post, 'color.hex', 'transparent')}
          class:fullbleed={post.imageLayout == 'fullBleed'}
          class:proportional={post.imageLayout == 'proportional'}>
          <img
            alt={postContent.title}
            src={urlFor(postContent.mainImage.asset)
              .width(800)
              .quality(90)
              .auto('format')
              .url()} />
        </div>
      {/if}

      <div class="ingress">
        {#if postContent.ingress && postContent.ingress.content && Array.isArray(postContent.ingress.content)}
          {@html renderBlockText(postContent.ingress.content)}
        {/if}
      </div>
    </div>
  </a>
{/await}
