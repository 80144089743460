<script>
  // # # # # # # # # # # # # #
  //
  //  SINGLE: MEDVERKANDE
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { loadData, renderBlockText } from "../../sanity.js"
  import { links } from "svelte-routing"
  import { fade } from "svelte/transition"

  // *** PROPS
  export let slug = ""

  // STORES
  import { currentPost } from "../../stores.js"

  // *** COMPONENTS
  import Footer from "../../Components/Footer.svelte"
  import ImageBlock from "../../Components/Blocks/ImageBlock.svelte"
  import VideoBlock from "../../Components/Blocks/VideoBlock.svelte"
  import AudioBlock from "../../Components/Blocks/AudioBlock.svelte"
  import EmbedBlock from "../../Components/Blocks/EmbedBlock.svelte"
  import ListItem from "../../Components/ListItem.svelte"

  // *** CONSTANTS
  const query = "*[_type == 'medverkande' && slug.current == $slug][0]"
  const params = { slug: slug }

  let post = loadData(query, params)
  let linkedPosts = []

  post.then(post => {
    currentPost.set(post)
    let fetchLinked = loadData("*[_type == 'post' && author[]._ref == $id]", {
      id: post._id,
    })
    fetchLinked.then(f => {
      linkedPosts = f
    })
  })
</script>

<style lang="scss">.medverkande {
  font-size: 17px;
  font-family: "Janson Text LT Std", times, serif;
  padding-bottom: 44px;
  width: calc(100% - 84px);
  overflow-x: hidden;
  margin-left: 42px;
  margin-right: 42px;
  padding-top: calc(66px + 22px);
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 44px; }
  .medverkande.empty {
    min-height: calc(100vh - 66px); }
  @media (max-width: 900px) {
    .medverkande {
      width: calc(100% - 28px);
      margin-left: 14px;
      margin-right: 14px; } }
  .medverkande h1 {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    padding: 0; }
    @media (max-width: 900px) {
      .medverkande h1 {
        font-size: 17px; } }

.meta {
  margin-bottom: 2rem;
  width: 600px;
  max-width: 100%; }

.author {
  font-size: 17px;
  line-height: 1.1em;
  margin-bottom: 10px;
  font-style: italic; }

.ingress {
  font-style: italic; }

.date {
  font-size: 12px;
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  padding-left: 2px;
  letter-spacing: 0.1em; }

.linked {
  margin-top: 22px;
  margin-left: 42px;
  margin-right: 42px; }
  @media (max-width: 900px) {
    .linked {
      margin-top: 0;
      margin-left: 14px;
      margin-right: 14px; } }

.column {
  padding-top: calc(66px + 22px);
  width: calc(50% - 42px);
  float: left;
  border-bottom: 1px solid #e4e4e4; }
  .column.first {
    margin-right: 42px; }
  @media (max-width: 900px) {
    .column {
      width: calc(100% - 28px); } }
</style>

{#await post then post}
  <div class="medverkande" class:empty={linkedPosts.length == 0} in:fade>
    <div class="about">
      <div class="meta">
        <!-- TITLE -->
        <h1 class="title">{post.title}</h1>
      </div>

      <!-- MAIN CONTENT -->
      {#if post.content}
        <div class="bio">
          {#each post.content.content as block}
            {#if block._type === 'block'}
              {@html renderBlockText(block)}
            {/if}
            {#if block._type === 'image'}
              <ImageBlock {block} />
            {/if}
            {#if block._type === 'videoBlock'}
              <VideoBlock {block} />
            {/if}
            {#if block._type === 'audioBlock'}
              <AudioBlock {block} />
            {/if}
            {#if block._type === 'embedBlock'}
              <EmbedBlock {block} />
            {/if}
          {/each}
        </div>
      {/if}
    </div>

    <!-- {#await linkedPosts then linkedPosts} -->
  </div>

  <div class="linked" use:links>
    {#each linkedPosts as linked}
      <ListItem post={linked} />
    {/each}
  </div>
  <!-- {/await} -->

  <Footer />
{/await}
