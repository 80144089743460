<script>
  // # # # # # # # # # # # # #
  //
  //  VIDEO BLOCK
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { onMount } from "svelte"
  import has from "lodash/has"
  import get from "lodash/get"

  // PROPS
  export let block = {}

  console.log("____ videoblock")
  console.dir(block)

  const videoUrl =
    "https://cdn.sanity.io/files/1tpw92x3/production/" +
    get(block, "videoFile.asset._ref", "")
      .replace("file-", "")
      .replace("-mp4", ".mp4")

  // // *** DOM REFERENCES
  let videoEl = {}

  // *** VARIABLES
  let time = 0
  let duration = 0
  let paused = true
  let showControls = true
  let showControlsTimeout
  let controlsTimeoutDuration = 1500

  // --- Video controls
  function handleMousemove(e) {
    // Make the controls visible, but fade out after
    // 1.5 seconds of inactivity
    clearTimeout(showControlsTimeout)
    showControlsTimeout = setTimeout(
      () => (showControls = false),
      controlsTimeoutDuration
    )
    showControls = true

    if (e.which !== 1) return // mouse not down
    if (!duration) return // video not loaded yet

    const { left, right } = this.getBoundingClientRect()
    time = (duration * (e.clientX - left)) / (right - left)
  }

  function handleMousedown(e) {
    function handleMouseup() {
      if (paused) {
        e.target.play()
      } else {
        e.target.pause()
      }
      cancel()
    }

    function cancel() {
      e.target.removeEventListener("mouseup", handleMouseup)
    }

    e.target.addEventListener("mouseup", handleMouseup)

    setTimeout(cancel, 200)
  }

  function format(seconds) {
    if (isNaN(seconds)) return "..."

    const minutes = Math.floor(seconds / 60)
    seconds = Math.floor(seconds % 60)
    if (seconds < 10) seconds = "0" + seconds

    return `${minutes}:${seconds}`
  }

  // *** ON MOUNT
  onMount(async () => {
    if (block.autoPlay) {
      let promise = videoEl.play()
      if (promise !== undefined) {
        promise.catch(err => {
          Sentry.captureException(err)
        })
      }
    }
  })
</script>

<style lang="scss">.video {
  width: 600px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  line-height: 22px;
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 22px;
  margin-top: 22px;
  color: #fefefe;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer; }
  .video--playing {
    cursor: grab; }
  .video .controls {
    transition: opacity 1s; }
  .video .buttons {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 160px;
    height: 160px;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    color: white; }
    @media (max-width: 900px) {
      .video .buttons {
        width: 80px;
        height: 80px; } }
    .video .buttons svg {
      width: 100%; }
  .video .play {
    position: relative;
    left: 4px; }
  .video .time {
    position: absolute;
    bottom: 10px;
    right: 10px; }
  .video progress {
    position: absolute;
    bottom: 0px;
    left: -2px;
    display: block;
    width: 100%;
    height: 7px;
    -webkit-appearance: none;
    appearance: none;
    pointer-events: none; }
    .video progress::-webkit-progress-value {
      background-color: white; }
    .video progress::-webkit-progress-bar {
      background-color: black; }
  .video.fullwidth {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .video.fullwidth img {
      max-height: 80vh; }
    .video.fullwidth .video-player {
      width: 960px;
      max-width: 96%; }
    .video.fullwidth.padded {
      padding-top: 88px;
      padding-bottom: 88px; }
</style>

<figure
  class="video"
  class:fullwidth={block.fullWidth}
  class:padded={has(block, 'backgroundColor.hex')}
  style={'background: ' + get(block, 'backgroundColor.hex', 'transparent')}>
  <video
    class="video-player"
    preload="metadata"
    loop={true}
    muted={block.autoPlay}
    src={videoUrl}
    on:mousemove={handleMousemove}
    on:mousedown={handleMousedown}
    bind:currentTime={time}
    bind:duration
    bind:paused
    bind:this={videoEl} />

  {#if !block.autoPlay}
    <div class="controls" style="opacity: {duration && showControls ? 1 : 0}">
      <!-- <progress value={time / duration || 0} /> -->

      <div class="buttons">
        {#if paused}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-play play">
            <polygon points="5 3 19 12 5 21 5 3" />
          </svg>
        {:else}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="0.4"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-pause pause">
            <rect x="6" y="4" width="4" height="16" />
            <rect x="14" y="4" width="4" height="16" />
          </svg>
        {/if}
      </div>
    </div>
  {/if}
</figure>
