<script>
  // # # # # # # # # # # # # #
  //
  //  paletten.net
  //
  // # # # # # # # # # # # # #

  // IMPORTS
  import { Router, Route } from "svelte-routing"
  import { fade } from "svelte/transition"

  // STORES
  import { menuActive, currentPost } from "./stores.js"

  // COMPONENTS
  import MenuBar from "./Components/MenuBar.svelte"
  import MetaData from "./Components/MetaData.svelte"

  // LANDING
  import Landing from "./Routes/Landing.svelte"
  // LIST
  import List from "./Routes/List/List.svelte"
  // ARTIKLAR
  import ArtiklarSingle from "./Routes/Single/ArtiklarSingle.svelte"
  // TIDSKRIFT
  import TidskriftSingle from "./Routes/Single/TidskriftSingle.svelte"
  // PROJEKT
  import ProjektSingle from "./Routes/Single/ProjektSingle.svelte"
  // MEDVERKANDE
  import MedverkandeSingle from "./Routes/Single/MedverkandeSingle.svelte"
  // PAGE
  import PageSingle from "./Routes/Single/PageSingle.svelte"
  // 404
  import Error404 from "./Routes/Error404.svelte"

  $: {
    if ($menuActive) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
  }
</script>

<style lang="scss" global>:global(html),
:global(body) {
  font-size: 17px;
  line-height: 22px;
  padding: 0;
  margin: 0;
  background: white;
  font-family: "Janson Text LT Std";
  scroll-behavior: smooth; }
  :global(html.no-scroll),
  :global(body.no-scroll) {
    overflow: hidden; }

:global(p) {
  margin: 0; }

:global(*) {
  box-sizing: border-box; }

:global(.meta) :global(p) {
  display: inline; }

:global(figure) {
  padding: 0;
  margin: 0; }

:global(.align-left) {
  text-align: left; }

:global(.align-center) {
  text-align: center; }

:global(.align-right) {
  text-align: right; }

:global(p) {
  margin-bottom: 22px; }

:global(iframe),
:global(video),
:global(audio),
:global(img) {
  max-width: 100%; }

:global(.right-aligned) {
  text-align: right; }

:global(.center-aligned) {
  text-align: center; }

:global(.embed-container) {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 720px;
  width: 100%;
  margin-bottom: 22px; }
  :global(.embed-container) :global(iframe) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

:global(.soundcloud-container) {
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 22px; }
  :global(.soundcloud-container) :global(iframe) {
    width: 100%;
    height: 100%;
    border: 0; }

:global(.note) {
  font-size: 17px;
  line-height: 22px; }

:global(.tags) {
  margin-top: 22px;
  margin-bottom: 22px;
  border-top: 1px solid #e4e4e4;
  padding-top: 22px;
  margin: 0;
  letter-spacing: 0.1em;
  font-size: 12px;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 22px;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  max-width: 100%; }
  :global(.tags) :global(a) {
    margin-bottom: 22px;
    float: left;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #e4e4e4;
    color: inherit;
    text-decoration: none; }
    :global(.tags) :global(a:hover) {
      background: #e4e4e4; }

:global(.medverkande) :global(a) {
  text-decoration: none; }
  :global(.medverkande) :global(a:hover) {
    color: #555555; }

:global(.content) {
  counter-reset: fnoteCounter; }
  :global(.content) :global(a) {
    text-decoration: underline;
    color: inherit; }
    :global(.content) :global(a:hover) {
      text-decoration: none; }
  :global(.content) :global(figcaption) :global(p) {
    margin-bottom: 0em;
    padding-left: 0;
    margin-left: 0; }
  :global(.content) :global(p),
  :global(.content) :global(h1),
  :global(.content) :global(h2),
  :global(.content) :global(h3),
  :global(.content) :global(h4),
  :global(.content) :global(ol),
  :global(.content) :global(ul) {
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    max-width: calc(100% - 20px);
    max-width: 100%; }
    @media (max-width: 900px) {
      :global(.content) :global(p),
      :global(.content) :global(h1),
      :global(.content) :global(h2),
      :global(.content) :global(h3),
      :global(.content) :global(h4),
      :global(.content) :global(ol),
      :global(.content) :global(ul) {
        max-width: calc(100%); } }
  :global(.content) :global(h1),
  :global(.content) :global(h2),
  :global(.content) :global(h3),
  :global(.content) :global(h4),
  :global(.content) :global(h5) {
    margin-top: 22px;
    margin-bottom: 22px; }
  :global(.content) :global(blockquote) {
    font-size: 15.3px;
    width: 600px;
    padding-left: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 20px); }
  :global(.content.intervju) {
    font-size: 22px;
    line-height: 28px; }
    :global(.content.intervju) :global(p),
    :global(.content.intervju) :global(h1),
    :global(.content.intervju) :global(h2),
    :global(.content.intervju) :global(h3),
    :global(.content.intervju) :global(h4),
    :global(.content.intervju) :global(ol),
    :global(.content.intervju) :global(ul) {
      font-size: 22px;
      margin-left: auto;
      margin-right: auto;
      width: 800px;
      max-width: calc(100% - 20px);
      max-width: 100%; }
      @media (max-width: 900px) {
        :global(.content.intervju) :global(p),
        :global(.content.intervju) :global(h1),
        :global(.content.intervju) :global(h2),
        :global(.content.intervju) :global(h3),
        :global(.content.intervju) :global(h4),
        :global(.content.intervju) :global(ol),
        :global(.content.intervju) :global(ul) {
          max-width: calc(100%); } }
    :global(.content.intervju) :global(.caption) :global(p) {
      font-size: 12px; }
  :global(.content.manifest) {
    font-size: 22px;
    line-height: 28px; }
    :global(.content.manifest) :global(p),
    :global(.content.manifest) :global(h1),
    :global(.content.manifest) :global(h2),
    :global(.content.manifest) :global(h3),
    :global(.content.manifest) :global(h4),
    :global(.content.manifest) :global(ol),
    :global(.content.manifest) :global(ul) {
      font-size: 22px;
      margin-left: auto;
      margin-right: auto;
      width: 800px;
      max-width: calc(100% - 20px);
      max-width: 100%; }
      @media (max-width: 900px) {
        :global(.content.manifest) :global(p),
        :global(.content.manifest) :global(h1),
        :global(.content.manifest) :global(h2),
        :global(.content.manifest) :global(h3),
        :global(.content.manifest) :global(h4),
        :global(.content.manifest) :global(ol),
        :global(.content.manifest) :global(ul) {
          max-width: calc(100%); } }
    :global(.content.manifest) :global(.caption) :global(p) {
      font-size: 12px; }

:global(.footnotes) {
  margin-top: 22px;
  margin-bottom: 22px; }
  :global(.footnotes) :global(ol) {
    border-top: 1px solid #e4e4e4;
    padding-top: 22px;
    margin: 0;
    padding-left: 20px;
    font-size: 17px;
    line-height: 22px;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    max-width: 100%; }
    :global(.footnotes) :global(ol) :global(li) {
      margin-bottom: 22px; }
      :global(.footnotes) :global(ol) :global(li) :global(p) {
        display: inline;
        margin: 0;
        padding: 0; }
      :global(.footnotes) :global(ol) :global(li:target) :global(p) {
        background: #ffff82; }
      :global(.footnotes) :global(ol) :global(li) :global(a) {
        color: inherit; }
      :global(.footnotes) :global(ol) :global(li) :global(.back-link) {
        color: inherit;
        padding: 4px;
        text-decoration: none;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 12px;
        letter-spacing: 0.1em;
        cursor: pointer; }
        :global(.footnotes) :global(ol) :global(li) :global(.back-link:hover) {
          text-decoration: underline; }

:global(.footnote-link) {
  counter-increment: fnoteCounter;
  color: inherit;
  text-decoration: none !important; }
  :global(.footnote-link:after) {
    position: relative;
    top: -6px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.8em;
    line-height: 22px;
    content: "(" counter(fnoteCounter) ")";
    padding-left: 4px; }
  :global(.footnote-link:hover) {
    text-decoration: underline !important; }
  :global(.footnote-link:target) {
    background: #ffff82; }

:global(.ingress) :global(p),
:global(.ingress) :global(h1),
:global(.ingress) :global(h2),
:global(.ingress) :global(h3),
:global(.ingress) :global(h4),
:global(.ingress) :global(ol),
:global(.ingress) :global(ul) {
  max-width: 60ch; }

:global(.bio) :global(p),
:global(.bio) :global(h1),
:global(.bio) :global(h2),
:global(.bio) :global(h3),
:global(.bio) :global(h4),
:global(.bio) :global(ol),
:global(.bio) :global(ul) {
  max-width: 60ch; }

:global(blockquote) {
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 22px; }

:global(h2),
:global(h3),
:global(h4) {
  font-size: 17px;
  font-weight: bold; }

:global(a) {
  color: inherit;
  text-decoration: none; }

:global(.grid) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  display: none;
  height: 6000px;
  pointer-events: none;
  z-index: 100000;
  opacity: 0.2; }
  :global(.grid) :global(.line) {
    width: 100vw;
    height: 22px;
    border-bottom: 1px solid red; }

:global(main.disabled) {
  transition: opacity 0.3s ease-out;
  opacity: 0.35;
  pointer-events: none;
  filter: grayscale(1); }

:global(.interview-name) {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 19.8px; }

:global(.caption) {
  text-align: left;
  float: left; }
  :global(.caption) :global(p) {
    font-size: 12px !important; }
</style>

<!-- METADATA -->
<MetaData post={$currentPost} />

<!-- MENU -->
<MenuBar />

<!-- MAIN -->
<main class:disabled={$menuActive}>
  <Router>
    <Route path="/" component={Landing} title="landing" />
    <!-- ARTIKLAR -->
    <Route path="/artiklar" component={List} category="artikel" />
    <Route path="/artiklar/:slug" component={ArtiklarSingle} />
    <!-- TIDSKRIFT -->
    <Route path="/tidskrift" component={List} category="tidskrift" />
    <Route path="/tidskrift/:slug" component={TidskriftSingle} />
    <!-- PROJEKT -->
    <Route path="/projekt" component={List} category="projekt" />
    <Route path="/projekt/:slug" component={ProjektSingle} />
    <!-- MEDVERKANDE -->
    <Route path="/medverkande/:slug" component={MedverkandeSingle} />
    <!-- SEARCH -->
    <Route path="/search/:term" component={List} category="search" />
    <!-- SEARCH -->
    <Route path="/taxonomy/:term" component={List} category="taxonomy" />
    <!-- PAGE -->
    <Route path="/:slug" component={PageSingle} />
    <!-- 404 -->
    <Route component={Error404} />
  </Router>
</main>

<!-- GRID -->
<div class="grid">
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
  <div class="line" />
</div>
