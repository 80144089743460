<script>
  // # # # # # # # # # # # # #
  //
  //  IMAGE BLOCK
  //
  // # # # # # # # # # # # # #

  // IMPORTS
  import { urlFor, renderBlockText } from "../../sanity.js"
  import get from "lodash/get"
  import has from "lodash/has"

  // PROPS
  export let block = {}
</script>

<style lang="scss">.image {
  width: 600px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  line-height: 22px;
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 22px; }
  .image.fullwidth {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .image.fullwidth img {
      max-height: 80vh; }
  .image.fullheight {
    width: 100%;
    max-height: unset;
    height: calc(100vh - 66px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
    padding-top: 0; }
    .image.fullheight img {
      max-height: 90vh; }
  .image.padded {
    padding-top: 88px;
    padding-bottom: 88px; }

.caption {
  text-align: left;
  float: left; }
  .caption p {
    font-size: 12px; }
</style>

<figure
  class="image"
  style={'background: ' + get(block, 'backgroundColor.hex', 'transparent')}
  class:padded={has(block, 'backgroundColor.hex')}
  class:fullwidth={block.fullWidth}
  class:fullheight={block.fullHeight}>
  <!-- IMAGE -->
  <img src={urlFor(block.asset).width(800).quality(90).auto('format').url()} />

  <!-- CAPTION -->
  <div class="text">
    {#if has(block, 'caption.content')}
      <figcaption>
        <!-- CAPTION -->
        <span class="caption>">
          {@html renderBlockText(block.caption.content)}
        </span>
      </figcaption>
    {/if}
  </div>
</figure>
