<script>
  // # # # # # # # # # # # # #
  //
  //  LANDING
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { fade } from "svelte/transition"
  import { urlFor, loadData, renderBlockText } from "../sanity.js"
  import { formattedDate } from "../global.js"
  import get from "lodash/get"

  // COMPONENTS
  import Authors from "./Authors.svelte"

  // *** PROPS
  export let post = {}
  export let category = ""

  let link = ""
  let query = "*[_id == '" + post._id + "']{..., author[]->{title, slug}}[0]"

  //   VARIABLES
  let postContent = loadData(query)

  postContent.then(l => {
    // console.dir(postContent)
    let dir = ""
    if (l._type === "post") {
      dir = "/artiklar/"
    } else if (l._type === "tidskrift") {
      dir = "/tidskrift/"
    } else if (l._type === "projekt") {
      dir = "/projekt/"
    }
    link = dir + l.slug.current
  })
</script>

<style lang="scss">.list-item,
.placeholder {
  display: flex;
  margin-bottom: 44px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 1px solid #e4e4e4;
  flex-wrap: wrap;
  width: 100%; }
  @media (max-width: 900px) {
    .list-item,
    .placeholder {
      flex-wrap: wrap;
      margin-bottom: 22px; } }
  .list-item .inner,
  .placeholder .inner {
    width: 100%;
    padding-bottom: 22px; }
  .list-item .column,
  .placeholder .column {
    width: 50%;
    float: left; }
    @media (max-width: 900px) {
      .list-item .column,
      .placeholder .column {
        width: 100%; } }
  .list-item .image,
  .placeholder .image {
    width: 100%;
    display: flex;
    padding-bottom: 22px; }
    .list-item .image img,
    .placeholder .image img {
      max-width: 100%;
      height: 352px;
      object-fit: cover; }
      @media (max-width: 900px) {
        .list-item .image img,
        .placeholder .image img {
          height: 176px; } }
    @media (max-width: 900px) {
      .list-item .image,
      .placeholder .image {
        width: 100%; } }
  .list-item .text,
  .placeholder .text {
    width: 100%;
    padding-right: 20px;
    text-decoration: none;
    color: black;
    max-width: 60ch; }
    @media (max-width: 900px) {
      .list-item .text,
      .placeholder .text {
        width: 100%; } }
    .list-item .text .title,
    .placeholder .text .title {
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 22px;
      font-weight: bold;
      max-width: 45ch; }
      @media (max-width: 900px) {
        .list-item .text .title,
        .placeholder .text .title {
          font-size: 17px; } }
    .list-item .text .author,
    .placeholder .text .author {
      font-size: 17px;
      line-height: 22px;
      font-style: italic;
      pointer-events: none; }
    .list-item .text .date,
    .placeholder .text .date {
      font-size: 12px;
      font-family: Helvetica, Arial, sans-serif;
      letter-spacing: 0.1em; }
  .list-item:hover .text .title,
  .placeholder:hover .text .title {
    text-decoration: underline; }
  .list-item.padded .inner,
  .placeholder.padded .inner {
    padding-bottom: 22px;
    margin-bottom: 22px; }
  .list-item.padded .text,
  .placeholder.padded .text {
    padding-left: 22px;
    padding-top: 22px; }
  .list-item.padded .image,
  .placeholder.padded .image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .list-item.padded .image img,
    .placeholder.padded .image img {
      max-width: 100%;
      height: 352px;
      object-fit: cover; }

.placeholder {
  background: #f0f0f0; }

.ingress {
  width: 100%;
  padding-right: 20px; }
</style>

{#await postContent then postContent}
  <a href={link} class="list-item full" in:fade class:padded={post.totalColor}>
    <div
      class="inner"
      style={'background: ' + get(post, 'color.hex', 'transparent')}>
      <div class="column">
        <div class="text">
          <!-- DATE -->
          {#if postContent.tidsPeriod}
            <div class="date">{postContent.tidsPeriod}</div>
          {:else if postContent.publicationDate}
            <div class="date">{formattedDate(postContent.publicationDate)}</div>
          {/if}
          <!-- AUTHOR -->
          {#if postContent.author}
            <div class="author">
              <Authors authors={postContent.author} />
            </div>
          {/if}
          <!-- TITLE -->
          <div class="title">{postContent.title}</div>
        </div>

        <div class="ingress">
          {#if postContent.ingress && postContent.ingress.content && postContent.ingress.content.length > 0}
            {@html renderBlockText(postContent.ingress.content)}
          {/if}
          <!-- {#if category == "tidskrift" && postContent.content.content}
            {@html renderBlockText(postContent.content.content)}
          {/if} -->
        </div>
      </div>

      <div class="column">
        <!-- IMAGE -->
        {#if post.imageLayout != 'noImage' && postContent.mainImage && postContent.mainImage.asset}
          <div
            class="image"
            style={'background: ' + get(post, 'color.hex', 'transparent')}
            class:fullbleed={post.imageLayout == 'fullBleed'}
            class:proportional={post.imageLayout == 'proportional'}>
            <img
              alt={postContent.title}
              src={urlFor(postContent.mainImage.asset)
                .width(800)
                .quality(90)
                .auto('format')
                .url()} />
          </div>
        {/if}
      </div>
    </div>
  </a>
{/await}
