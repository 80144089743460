<script>
  // # # # # # # # # # # # # #
  //
  //  FOOTER
  //
  // # # # # # # # # # # # # #

  // IMPORTS
  import { links } from "svelte-routing"
  import { loadData, renderBlockText } from "../sanity.js"

  const query = '*[_type == "footer"][0]'
  let post = loadData(query)
</script>

<style lang="scss">footer {
  width: 100vw;
  background: #f4f4f4;
  line-height: 22px;
  overflow: hidden;
  user-select: none;
  padding-left: 42px;
  padding-right: 42px;
  padding-top: 22px;
  padding-bottom: 88px;
  font-weight: normal;
  margin-top: 22px;
  font-size: 12px;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 0.1em; }
  @media (max-width: 900px) {
    footer {
      padding-left: 14px;
      padding-right: 14px;
      padding-bottom: 44px; } }
  footer .logo {
    display: inline-block; }

.meta p {
  display: inline; }

.column {
  width: calc(33.3333% - 28px);
  float: left; }
  .column.second {
    margin-left: 42px; }
  .column.third {
    margin-left: 42px; }
    .column.third a:hover {
      text-decoration: underline; }
  .column p {
    max-width: 40ch; }
  @media (max-width: 900px) {
    .column {
      width: 100%; }
      .column.second {
        margin-left: 0; }
      .column.third {
        margin-left: 0;
        display: none; } }

.menu-item {
  display: block; }
</style>

<footer>
  {#await post then post}
    <div class="column first">
      {@html renderBlockText(post.description.content)}
    </div>

    <div class="column second">
      {@html renderBlockText(post.address.content)}
    </div>

    <div class="column third" use:links>
      <div class="menu-item"><a href="/artiklar">Artiklar</a></div>
      <div class="menu-item"><a href="/projekt">Projekt</a></div>
      <div class="menu-item"><a href="/tidskrift">Tidskrift</a></div>
      <div class="menu-item"><a href="/om-paletten">Om Paletten</a></div>
      <div class="menu-item"><a href="/prenumerationer">Prenumeration</a></div>
      <div class="menu-item"><a href="/nyhetsbrev">Nyhetsbrev</a></div>
      <div class="menu-item"><a href="/kontakt">Kontakt</a></div>
    </div>
  {/await}
</footer>
