<script>
  // # # # # # # # # # # # # #
  //
  //  SINGLE: ARTIKEL
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { loadData, renderBlockText } from "../../sanity.js"
  import { formattedDate } from "../../global.js"
  import get from "lodash/get"
  import isArray from "lodash/isArray"
  import flatMap from "lodash/flatMap"
  import { fade } from "svelte/transition"

  // *** PROPS
  export let slug = ""

  // COMPONENTS
  import Footer from "../../Components/Footer.svelte"
  import Authors from "../../Components/Authors.svelte"
  import ImageBlock from "../../Components/Blocks/ImageBlock.svelte"
  import VideoBlock from "../../Components/Blocks/VideoBlock.svelte"
  import AudioBlock from "../../Components/Blocks/AudioBlock.svelte"
  import EmbedBlock from "../../Components/Blocks/EmbedBlock.svelte"

  // STORES
  import { currentPost } from "../../stores.js"

  // ** CONSTANTS
  const query =
    "*[slug.current == $slug]{..., 'projektContext': *[_type=='projekt' && references(^._id)]{ ... }, 'tidskriftContext': *[_type=='tidskrift' && references(^._id)]{ ... }, 'pdfUrl': pdfFile.asset->url, author[]->{title, slug}}[0]"
  const params = { slug: slug }

  let post = loadData(query, params)
  let footnotePosts = []

  post.then(post => {
    console.log("post", post)
    currentPost.set(post)
    let a = flatMap(
      post.content.content.filter(c => c._type == "block").map(x => x.markDefs)
    )
    footnotePosts = a.filter(x => x._type === "footnote")
  })
</script>

<style lang="scss">.single {
  font-size: 17px;
  font-family: "Janson Text LT Std", times, serif;
  margin: 0;
  padding-bottom: 44px;
  width: 100vw;
  overflow-x: hidden;
  padding-top: calc(66px + 11px);
  min-height: calc(100vh - 66px); }
  @media (max-width: 900px) {
    .single {
      width: calc(100% - 28px);
      margin-left: 14px;
      margin-right: 14px; } }
  .single h1 {
    font-size: 17px;
    line-height: 22px;
    margin: 0;
    padding: 0; }
    @media (max-width: 900px) {
      .single h1 {
        font-size: 17px; } }

.meta {
  margin-bottom: 44px;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  max-width: 100%; }
  .meta .date {
    font-size: 12px;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 11px;
    padding-left: 2px;
    letter-spacing: 0.1em; }
  .meta .authors {
    font-size: 17px;
    line-height: 22px; }
  .meta.intervju {
    width: 800px;
    padding-bottom: 28px;
    margin-bottom: 56px;
    border-bottom: 1px solid #e4e4e4;
    line-height: 28px; }
    .meta.intervju .authors {
      font-size: 22px;
      margin-bottom: 14px; }
    .meta.intervju .title {
      font-size: 22px;
      line-height: 28px; }
  .meta.manifest {
    width: 800px;
    padding-bottom: 28px;
    margin-bottom: 56px;
    border-bottom: 1px solid #e4e4e4;
    line-height: 28px; }
    .meta.manifest .authors {
      font-size: 32px;
      margin-bottom: 14px; }
    .meta.manifest .title {
      font-size: 32px;
      font-family: Helvetica, Arial, sans-serif;
      font-weight: 900;
      line-height: 28px; }

.ingress {
  font-style: italic; }

.pdf-download {
  border-bottom: 1px solid #e4e4e4;
  line-height: 22px;
  overflow: hidden;
  user-select: none;
  font-weight: normal;
  display: inline-block;
  font-size: 12px;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 0.1em;
  color: #555555; }
  .pdf-download svg {
    transform-origin: center;
    transform: scale(0.9) translateY(9px);
    margin-right: 5px; }
    .pdf-download svg path {
      fill: #555555; }
  .pdf-download:hover {
    color: black;
    border-bottom: 1px solid #555555; }
    .pdf-download:hover svg path {
      fill: black; }

.publication-context {
  border-bottom: 1px solid #e4e4e4;
  line-height: 22px;
  user-select: none;
  overflow: visible;
  font-weight: normal;
  color: #555555;
  display: inline-block;
  font-size: 12px;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 0.1em; }
  .publication-context svg {
    transform: scale(0.9) translateY(9px);
    margin-right: 5px; }
    .publication-context svg path {
      fill: #555555; }
  .publication-context:hover {
    color: black;
    border-bottom: 1px solid #555555; }
    .publication-context:hover svg path {
      fill: black; }

.top-bar {
  margin-bottom: 33px;
  margin-left: 42px;
  margin-right: 42px;
  overflow: visible;
  display: flex;
  flex-direction: column; }
</style>

{#await post then post}
  <div class="single">
    <!-- TOPBAR -->
    <div class="top-bar">
      <!-- TIDSKRIFT -->
      {#if post.tidskriftContext && post.tidskriftContext.length > 0}
        <div>
          <a
            href={'/tidskrift/' + post.tidskriftContext[0].slug.current}
            class="publication-context"><svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"><path
                d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" /></svg>Publicerad
            i
            {post.tidskriftContext[0].title}</a>
        </div>
      {/if}
      <!-- PROJEKT -->
      {#if post.projektContext && post.projektContext.length > 0}
        <div>
          <a
            href={'/projekt/' + post.projektContext[0].slug.current}
            class="publication-context"><svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"><path
                d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" /></svg>Publicerad
            i
            {post.projektContext[0].title}</a>
        </div>
      {/if}
      <!-- PDF -->
      {#if post.pdfUrl}
        <div>
          <a
            href={post.pdfUrl}
            class="pdf-download"
            download
            target="_blank"><svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"><path
                d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" /></svg>{post.pdfLinkText ? post.pdfLinkText : 'PDF'}</a>
        </div>
      {/if}
    </div>
    <!-- META -->
    <div class="meta {post.textTyp}" in:fade>
      {#if post.publicationDate}
        <div class="date">{formattedDate(post.publicationDate)}</div>
      {/if}
      <!-- TITLE -->
      <h1 class="title">{post.title}</h1>
      <!-- AUTHOR -->
      {#if post.author}
        <div class="authors">
          <Authors authors={post.author} />
        </div>
      {/if}
      <!-- PDF DOWNLOAD -->
    </div>

    <!-- MAIN CONTENT -->
    {#if post.content && post.content.content && isArray(post.content.content)}
      <div class="content {post.textTyp}" in:fade={{ delay: 300 }}>
        {#each post.content.content as block}
          {#if block._type === 'block'}
            {@html renderBlockText(block)}
          {/if}
          {#if block._type === 'image'}
            <ImageBlock {block} />
          {/if}
          {#if block._type === 'videoBlock'}
            <VideoBlock {block} />
          {/if}
          {#if block._type === 'audioBlock'}
            <AudioBlock {block} />
          {/if}
          {#if block._type === 'embedBlock'}
            <EmbedBlock {block} />
          {/if}
        {/each}
      </div>
      <!-- FOOTNOTES -->
      <div class="footnotes">
        <ol>
          {#each footnotePosts as footnote}
            <li id={'note-' + footnote._key}>
              {#if isArray(get(footnote, 'content.content', false))}
                {@html renderBlockText(footnote.content.content)}
              {/if}
              <span
                on:click={e => {
                  const targetEl = document.querySelector('#link-' + footnote._key)
                  // console.log(targetEl)
                  if (targetEl) {
                    // console.log(targetEl.offsetTop)
                    window.scrollTo({
                      top: targetEl.offsetTop - 100,
                      left: 0,
                      behavior: 'smooth',
                    })
                  }
                }}
                class="back-link">(BACK)</span>
            </li>
          {/each}
        </ol>
      </div>

      <!-- TAGS -->
      {#if post.tags}
        <div class="tags">
          {#each post.tags as tag}
            <a href={'/taxonomy/' + tag}>
              <!-- <a
              href={'/taxonomy/' + slugify(tag, {
                  replacement: '-',
                  lower: true,
                  strict: true
                })}> -->
              {tag}
            </a>
          {/each}
        </div>
      {/if}
    {/if}
  </div>

  <Footer />
{/await}
