<script>
  // # # # # # # # # # # # # #
  //
  //  SINGLE: PAGE
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { loadData, renderBlockText } from "../../sanity.js"
  import get from "lodash/get"
  import flatMap from "lodash/flatMap"
  import { links } from "svelte-routing"
  import { fade } from "svelte/transition"

  // *** PROPS
  export let slug = ""

  // STORES
  import { currentPost } from "../../stores.js"

  // *** COMPONENTS
  import Footer from "../../Components/Footer.svelte"
  import Authors from "../../Components/Authors.svelte"
  import MailingListForm from "../../Components/MailingListForm.svelte"
  import ImageBlock from "../../Components/Blocks/ImageBlock.svelte"
  import VideoBlock from "../../Components/Blocks/VideoBlock.svelte"
  import AudioBlock from "../../Components/Blocks/AudioBlock.svelte"
  import EmbedBlock from "../../Components/Blocks/EmbedBlock.svelte"

  // *** CONSTANTS
  const query = "*[_id == $slug][0]"
  const medverkandeQuery =
    "*[_type == 'medverkande']{..., 'connectedPosts': *[_type=='post' && references(^._id)]{ title }} | order(title asc)"

  // *** VARIABLES
  let params = { slug: slug }
  let footnotePosts = []
  let oldSlug = slug
  let post = loadData(query, params)
  let medverkande = loadData(medverkandeQuery)
  let mArray = []

  post.then(post => {
    currentPost.set(post)
    let a = flatMap(
      post.content.content.filter(c => c._type == "block").map(x => x.markDefs)
    )
    footnotePosts = a.filter(x => x._type === "footnote")
  })

  medverkande.then(medverkande => {
    console.dir(medverkande)
    mArray = medverkande
  })

  $: {
    if (slug !== oldSlug) {
      oldSlug = slug
      params = { slug: slug }
      post = loadData(query, params)
      post.then(post => {
        currentPost.set(post)
        let a = flatMap(
          post.content.content
            .filter(c => c._type == "block")
            .map(x => x.markDefs)
        )
        footnotePosts = a.filter(x => x._type === "footnote")
      })
    }
  }
</script>

<style lang="scss">.page {
  font-size: 17px;
  font-family: "Janson Text LT Std", times, serif;
  padding-bottom: 44px;
  width: calc(100% - 84px);
  overflow-x: hidden;
  margin-left: 42px;
  margin-right: 42px;
  min-height: calc(100vh - 66px); }
  @media (max-width: 900px) {
    .page {
      width: calc(100% - 28px);
      margin-left: 14px;
      margin-right: 14px; } }
  .page .meta {
    margin-bottom: 44px;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    max-width: 100%; }
    .page .meta .title {
      font-size: 17px;
      line-height: 22px;
      margin: 0;
      padding: 0;
      font-weight: bold; }
  .page .column {
    padding-top: calc(66px + 22px);
    width: calc(50% - 42px);
    float: left; }
    .page .column.first {
      margin-right: 42px; }
    @media (max-width: 900px) {
      .page .column {
        width: calc(100% - 28px); }
        .page .column.second {
          padding-top: 22px; } }

.subscribe-button {
  line-height: 22px;
  height: 22px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 22px;
  letter-spacing: 0.2em;
  font-size: 0.7em;
  border: 1px solid #e4e4e4;
  padding: 20px; }

.medverkande {
  column-count: 2; }
  @media (max-width: 900px) {
    .medverkande {
      column-count: 1; } }
  .medverkande a {
    text-decoration: none; }

.post-counter {
  font-size: 12px;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 0.1em; }
</style>

{#await post then post}
  <div class="page" use:links>
    <div class="column first" in:fade>
      {#if slug !== 'nyhetsbrev'}
        <div class="meta">
          <!-- <div class="date">{formattedDate(post.publicationDate)}</div> -->
          <!-- AUTHOR -->
          {#if post.author}
            <Authors authors={post.author} />
          {/if}
          <!-- TITLE -->
          <h1 class="title">{post.title}</h1>
        </div>

        <!-- MAIN CONTENT -->
        {#if post.content}
          <div class="content">
            {#each post.content.content as block}
              {#if block._type === 'block'}
                {@html renderBlockText(block)}
              {/if}
              {#if block._type === 'image'}
                <ImageBlock {block} />
              {/if}
              {#if block._type === 'videoBlock'}
                <VideoBlock {block} />
              {/if}
              {#if block._type === 'audioBlock'}
                <AudioBlock {block} />
              {/if}
              {#if block._type === 'embedBlock'}
                <EmbedBlock {block} />
              {/if}
            {/each}
            <!-- {#if slug == 'prenumerationer'}
            <a
              href="https://www.natverkstan.net/wp-content/plugins/konturiDb/prenumerera.php?id=146"
              target="_blank"
              class="subscribe-button">
              Teckna en prenumeration på Paletten
            </a>
          {/if} -->
          </div>

          {#if footnotePosts && footnotePosts.length > 0}
            <div class="footnotes">
              <ol>
                {#each footnotePosts as footnote}
                  <li id={'note-' + footnote._key}>
                    {@html renderBlockText(footnote.content.content)}
                    <a
                      href={'#link-' + footnote._key}
                      class="back-link">(BACK)</a>
                  </li>
                {/each}
              </ol>
            </div>
          {/if}
        {/if}
      {/if}

      {#if slug == 'nyhetsbrev'}
        <div class="meta">
          <h1 class="title">Nyhetsbrev/Newsletter</h1>
        </div>
        <div class="content">
          <MailingListForm />
        </div>
      {/if}
    </div>

    {#if slug == 'om-paletten'}
      <div class="column second" in:fade={{ delay: 300 }}>
        <div class="meta">
          <h1 class="title">Medverkande</h1>
        </div>

        <div class="content medverkande">
          {#each mArray as m, i}
            <div>
              <a href={'/medverkande/' + get(m, 'slug.current', '')}>
                {m.title}
                {#if m.connectedPosts.length > 0}
                  <span class="post-counter">({m.connectedPosts.length})</span>
                {/if}
              </a>
              <!-- {#if i < mArray.length - 1},&nbsp;{/if} -->
            </div>
          {/each}
        </div>
      </div>
    {/if}
  </div>

  <Footer />
{/await}
